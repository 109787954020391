// style guide: https://xd.adobe.com/view/3d03c2bc-6e82-48ac-9a28-2adb683c339b-6850/

$container-max-widths: (
  xs: 1000px,
  sm: 1000px,
  md: 1000px,
  lg: 1000px,
  xl: 1000px
);

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$grid-breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl
);

$y-padding: 0.875rem;

///////////////////////////
///                     ///
///       Colors        ///
///                     ///
///////////////////////////

$primary: #0064dd;
$secondary: #000082;
$success: #2b7665;
$info: #31816f;
$warning: #b8c924;
$danger: #c91528;
$light: #fff;
$dark: #000;

//custom color to be mapped
$accent: #83dcc1;
$hover: #0d86ff;
$text: #000000de;
$focus: #e59700;
$secondary-text: rgba(0, 0, 0, 0.6);
$darker-text: rgba(0, 0, 0, 0.9);
$line: #a9a9a9;
$disabled-bg: #dddddd;
$disabled-text: #727272;
$light-success: #f6fefc;
$tooltip-bg: #575b76;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  // add any additional color below
  accent: $accent,
  hover: $hover,
  text: $text,
  secondary-text: $secondary-text,
  focus: $focus,
  line: $line,
  light-success: $light-success,
  tooltip-bg: $tooltip-bg
);

$body-bg: $light;
$body-color: $text;
$hr-color: $line;

///////////////////////////
///                     ///
///         Font        ///
///                     ///
///////////////////////////

@font-face {
  font-family: 'GalanoGrotesque';
  src: url('fonts/GalanoGrotesque-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GalanoGrotesque';
  src: url('fonts/GalanoGrotesque-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GalanoGrotesque';
  src: url('fonts/GalanoGrotesque-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'GalanoGrotesque';
  src: url('fonts/GalanoGrotesque-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

$font-family-sans-serif: 'GalanoGrotesque';

body {
  font-family: $font-family-sans-serif !important; // force font family when sharing themes
}

$font-size-base: 1rem; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 0.875;
$font-size-sm: $font-size-base * 0.75; // disclaimer
$font-size-lg: $font-size-base;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $font-size-base,
  4: $h3-font-size,
  5: $font-size-sm
);

$display-font-sizes: (
  1: 3.25rem,
  // display
  2: 2.9375rem,
  // title
  3: 2.25rem // subhead
);

$line-height-base: 1.25;
$headings-line-height: 1.5;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-weights: (
  400: $font-weight-normal,
  500: $font-weight-medium,
  600: $font-weight-semibold,
  700: $font-weight-bold,
  'normal': $font-weight-normal,
  'medium': $font-weight-medium,
  'semibold': $font-weight-semibold,
  'bold': $font-weight-bold
);

$utilities: (
  'font-weight': (
    property: font-weight,
    class: fw,
    values: $font-weights
  )
);

.fs-1-r {
  font-size: $h1-font-size;
}
.fs-2-r {
  font-size: $h2-font-size;
}
.fs-3-r {
  font-size: $font-size-base;
}
.fs-4-r {
  font-size: $h3-font-size;
}

@media only screen and (max-width: $md) {
  .fs-1-r {
    font-size: $h2-font-size;
  }
  .fs-2-r {
    font-size: $font-size-base;
  }
  .fs-3-r {
    font-size: $h3-font-size;
  }
  .fs-4-r {
    font-size: $font-size-sm;
  }
}

///////////////////////////
///                     ///
///      Accordion      ///
///                     ///
///////////////////////////

.accordion-secondary {
  .accordion-item {
    border-radius: 0 !important;
    border: solid 4px $secondary;
  }
  .accordion-button {
    width: 100%;
    text-align: center !important;
    padding: 3px 0 !important;
    border-radius: 0 !important;
    text-align: center !important;
    background: $secondary !important;
    color: $light !important;
    justify-content: center;
  }
  .accordion-button::after {
    margin-left: 0.5rem !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    transform: rotate(-180deg) !important;
  }
  .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg) !important;
  }
}

///////////////////////////
///                     ///
///        Alert        ///
///                     ///
///////////////////////////

$alert-border-radius: 0.25rem;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: 1px;
$alert-bg-scale: 47%;
$alert-border-scale: -75%;
$alert-color-scale: 0%;

.alert {
  font-size: $h3-font-size;
}

///////////////////////////
///                     ///
///        Border       ///
///                     ///
///////////////////////////

$border-radius: 0.25rem;
$border-radius-sm: 0.125rem;
$border-radius-lg: 0.3rem;
$border-radius-xl: 0.625rem;
$border-radius-pill: 50rem;

.rounded-lg-end {
  border-top-right-radius: $border-radius-xl !important;
  border-bottom-right-radius: $border-radius-xl !important;
}

///////////////////////////
///                     ///
///        Button       ///
///                     ///
///////////////////////////

$btn-font-weight: $font-weight-semibold;

$input-btn-focus-color-opacity: 0.1;
$input-btn-focus-width: 0;
$btn-hover-bg-shade-amount: 25%;
$btn-hover-bg-tint-amount: 25%;
$btn-active-bg-tint-amount: 50%;
$btn-active-bg-shade-amount: 50%;
$btn-disabled-opacity: 1;

$btn-padding-y: $y-padding;
$btn-padding-y-sm: 0.40625rem;
$btn-padding-x-sm: 0.75rem;

$input-btn-padding-y-lg: $y-padding !default;

$btn-close-color: #000d;
$btn-close-bg: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{$btn-close-color}" class="bi bi-x-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/></svg>');

.btn-group {
  border-radius: $border-radius-lg;
  box-sizing: border-box;
}

.btn-group .btn {
  font-weight: $font-weight-normal;
  font-size: $font-size-base;
  border-color: $line !important;
}

.btn-group label:hover {
  background-color: inherit;
  color: inherit;
}

.icon {
  display: inline-block;
  height: 16px;
  width: 16px;
  background: transparent center center no-repeat;
}
/*
.chevron-down {
  background-image: url(../assets/chevron-down.svg)
}
.chevron-up {
  background-image: url(../assets/chevron-up.svg)
}
*/
.btn.btn-primary[disabled] {
  background-color: $disabled-bg;
  color: $disabled-text;
  border-color: $disabled-bg;
}

.btn-close {
  font-size: 24px;
}

.btn-group > .btn-check:checked + .btn {
  font-weight: $font-weight-semibold;
}

.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
  background-color: $disabled-bg;
}

.btn-outline-accent {
  color: $text !important;
}

.toggle-buttons .btn {
  background-color: $light;
  border: none;
}

///////////////////////////
///                     ///
///         Card        ///
///                     ///
///////////////////////////

$card-border-width: 0;

.card {
  border-radius: $border-radius-xl !important;
}

.card-body {
  border-radius: $border-radius-xl;
  box-shadow: 0 3px 6px #00000029;
  padding: 32px !important;
}

///////////////////////////
///                     ///
///     Form / Input    ///
///                     ///
///////////////////////////

$input-bg: white;
$input-font-size: $font-size-sm;
$input-focus-border-color: $focus;
$input-focus-width: 2px;
$input-focus-box-shadow: 0px 0px 0px 1px $focus;
$form-label-margin-bottom: 0;
//$form-label-font-size: $font-size-sm;

$input-btn-font-family: $font-family-sans-serif;
$input-btn-padding-y: 0.75rem;

$input-group-addon-color: white;
$input-group-addon-bg: $secondary;

$form-select-padding-x-lg: 1rem;
$form-select-padding-y-lg: $y-padding;

.input-group-text {
  font-size: $h1-font-size !important;
  padding: 10px 18px 8px !important;
}

///////////////////////////
///                     ///
///        Modal        ///
///                     ///
///////////////////////////

$modal-header-border-width: 2px;
$modal-footer-border-width: 2px;
$modal-backdrop-opacity: 0.85;
$modal-header-border-color: $line;
$modal-header-border-color: $line;
$modal-md: 600px;

.modal-dialog-centered {
  min-height: calc(100% - 15rem) !important;
}
.modal-header,
.modal-footer {
  background-color: $light;
}
.modal-title {
  font-weight: 600 !important;
  color: $secondary;
  margin: auto;
  font-size: $h2-font-size !important;
}
.modal-body {
  padding: 1.5rem 2rem 2rem !important;
}

///////////////////////////
///                     ///
///         Nav         ///
///                     ///
///////////////////////////

$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$primary}' stroke-linecap='square' stroke-miterlimit='10' stroke-width='4' d='M4 7h24M4 16h24M4 25h24'/></svg>");
$navbar-light-toggler-border-color: rgba(#000, 0);
$navbar-toggler-padding-x: 0;

$nav-tabs-border-color: $line;
$nav-tabs-border-radius: $border-radius-xl;
$nav-tabs-link-active-color: $text;
$nav-tabs-link-active-bg: $accent;

.nav-tabs {
  margin-bottom: 0 !important;
  border: none !important;
  display: flex;
}

.nav-tabs li {
  flex-grow: 1;
  max-width: 240px;
}

.nav-tabs button {
  color: $text;
  font-size: 1.125rem;
  padding: 1.125rem 0.5rem !important;
  width: 100%;
  border: 1px solid $line !important;
}

.nav-tabs button:hover {
  color: $text;
}

.tab-pane {
  background-color: white;
  border-radius: 0 10px 10px;
  box-shadow: 0 3px 6px #00000029;
}

///////////////////////////
///                     ///
///       tooltip       ///
///                     ///
///////////////////////////

$tooltip-opacity: 1;
$tooltip-font-size: 14px;
$tooltip-max-width: 380px;
$tooltip-padding-y: 10px;
$tooltip-padding-x: 10px;

// Badges

.badge {
  @each $state, $value in $theme-colors {
    &.text-bg-#{$state},
    &.bg-#{$state} {
      color: $value;
    }
  }
  &.text-bg-info,
  &.bg-info {
    background-color: #c2dbfa !important;
  }
  &.text-bg-danger,
  &.bg-danger {
    background-color: #f9dcde !important;
  }
  &.text-bg-warning,
  &.bg-warning {
    background-color: #f8e2b3 !important;
  }
  &.text-bg-success,
  &.bg-success {
    background-color: #d6f0ea !important;
  }
  &.text-bg-pending,
  &.bg-pending {
    background-color: #ddd !important;
  }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// override component styles
@import '_alert.scss';
