@mixin alert-variant($background, $border, $color) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;

  .alert-link {
    color: shade-color($color, 20%);
  }
}

@each $state, $value in $theme-colors {
  $alert-background: adjust-color(
    $value,
    $saturation: $alert-bg-scale * 0.02,
    $lightness: $alert-bg-scale
  );
  $alert-border: shift-color($value, $alert-border-scale);
  $alert-color: shift-color($value, $alert-color-scale);
  $btn-close-color: $value;

  .alert-#{$state} {
    @include alert-variant($alert-background, $alert-border, $alert-color);
  }
}

.alert-dismissible .btn-close {
  padding: 0.75rem 1rem;
}
